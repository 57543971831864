import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../sass/main.scss';

const SuccessPage = () => (
  <Layout>
    <SEO title="Success" />
    <h1>Thanks</h1>
    <p>
        Thanks for contacting me. I'll get back to you as soon as possible.
    </p>
  </Layout>
)

export default SuccessPage
